<template>
  <div class="home">
    <div class="main">
      <my-no-data v-if="list.length == 0" conentTop="50%"></my-no-data>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index" @click="goToDetail(item)">
          <!-- <div class="title" v-if="item.topic_type != '1'">
            <div v-if="item.type != 'references'">
              {{ item.sectinon_name }}
              ({{ item.geology_latitude_decimal }},{{ item.geology_longitude_decimal }})
            </div>
            <div v-else v-html="item.image_title"></div>
          </div> -->



          <!--Fossil Ontology -->
          <div class="single">
            <div class="title"><span v-html="item.imageTitle"></span><span class="tag">Specimen</span></div>
            <div class="info" style="position: relative;">
              <div class="fs12">
                {{ $t('details.age') }}：{{ $i18n.locale != 'en-us' ? item.age : item.ageEn }}
              </div>
              <div class="fs12">
                {{ $t('details.specimentsId') }}：{{ item.specimentsId }}
              </div>
              <div class="fs12" style="width:240px">
                {{ $t('details.locality') }}：{{ $i18n.locale != 'en-us' ? item.locality : item.localityEn }}
              </div>
              <div class="msg f6">
                {{ $t('details.housedIn') }}：{{ item.housedIn }}
              </div>
              <img v-if="item.thumbnailUrl" :src="item.thumbnailUrl" alt="" style="margin-top:5px" class="infoimage" />
            </div>
            <!-- <img v-if="item.thumbnail_url" :src="item.thumbnail_url" alt="" style="margin-top:5px" /> -->
          </div>

          <!-- <div class="type" v-if="item.type.indexOf('GBDB') != -1">{{ item.type }}</div> -->
        </div>
      </div>

      <div class="page">
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import formApi from "@/api/image/index";
import MyNoData from '@/components/noData/index.vue';
import { mapState } from "vuex";

export default {
  name: 'index',
  components: {
    MyNoData,
  },
  data () {
    return {
      list: [],
      searchVal: '',
      total: 0,
      type: '',
    };
  },
  computed: {
    ...mapState(['searchList']),
  },
  watch: {
    $route (val) {
      if (val) {
        this.type = val.query.type;
        this.init(this.type);
      }
    },
  },
  methods: {
    handleCurrentChange (e) {
      this.init(this.type, e);
    },
    goToDetail (item) {

      this.$router.push({
        path: 'info2',
        query: {
          id: item.infoId,
        },
      });

    },
    async init (type, pageNum = 1) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      const data = this.$store.state.common.searchList
      formApi.getTemplate(data).then(res => {
        if (res) {
          this.list = res.rows
          this.total = res.total
        }
      })


      loading.close();
    },
  },
  created () { },
  mounted () {
    this.type = this.$route.query.type;
    if (this.type != '') {
      this.init(this.type);
    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: auto;
}

.BigBox {
  height: 800px;
  display: flex;
  overflow: hidden;

  .one {
    width: 50px;
    height: 100%;
    background: #2440b3;
  }

  .two {

    // display: flex;
    .t1 {
      width: 50px;
      background: red;
    }
  }
}

.home {
  overflow: hidden;
  padding-bottom: 80px;

  .main {
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
    box-sizing: border-box;

    .list {
      width: 900px;
      min-height: 90vh;

      // height: 100vh;
      .item {
        margin-bottom: 34px;
        display: flex;
        align-items: center;

        .single {
          cursor: pointer;

          div {
            font-size: 13px;

            span {
              font-size: 15px;
            }
          }

          .info {
            div {
              margin: 10px 0;
            }
          }

          .infoimage {
            position: absolute;
            width: 170px;
            height: 100px;
            left: 350px;
            border-radius: 5%;
            bottom: 0;
          }
        }

        .title {
          width: 800px;
          font-size: 18px !important;
          text-decoration: underline;
          font-weight: 400;
          color: #2440b3;
          line-height: 25px;

          cursor: pointer;

          p {
            text-decoration: none !important;
          }

          .tag {

            display: inline-block;
            border: 1px solid #70ABFF;
            font-size: 12px;
            color: #70ABFF;
            padding: 0 6px;
            border-radius: 4px;
            line-height: 16px;
            margin-left: 10px;
          }
        }

        img {
          width: 170px;
          height: 105px;
          display: block;
          border-radius: 10px;
        }

        .type {
          width: 100px;
          height: 16px;
          font-size: 11px;

          font-weight: 400;
          color: #70abff;
          line-height: 16px;
          margin-left: 20px;
        }
      }
    }

    .page {

      // background: #f8f8f8;
      ::v-deep .el-pagination {
        .btn-prev {
          background: #fff;
        }

        .btn-next {
          background: #fff;
        }

        .el-pager {
          .number {
            background: #fff;
            color: #2440b3;
          }

          .number.active {
            background: #2440b3;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
